import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import Logo from "../resource/Logo.png";
import { NavLink } from "react-router-dom";
import { IoMdClose, IoMdContacts } from "react-icons/io";
import { HiOutlineMenuAlt2, HiOutlineMenuAlt3 } from "react-icons/hi";

function Header() {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [menuTitle, setMenuTitle] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [muneLinks, setMenuLinks] = useState({});

  const Services_nav = [
    // {
    //   name: "Design",
    //   url: "",
    //   className: "",
    // },
    {
      name: "Engineering",
      url: "/Services/engineering",
      className: "",
    },
    {
      name: "Optimizing",
      url: "/Services/optimize",
      className: "",
    },
    {
      name: "Modernization",
      url: "/Services/modernization",
      className: "",
    },
    // {
    //   name: "Consulting",
    //   url: "",
    //   className: "",
    // },
  ];

  const Industries_nav = [
    {
      name: "Consumer",
      url: "/Industries/consumer",
      className: "",
    },
    // {
    //   name: "Education",
    //   url: "/Industries/education",
    //   className: "",
    // },
    // {
    //   name: "Energy & Resources",
    //   url: "/Industries/Resources",
    //   className: "",
    // },
    // {
    //   name: "Financial Services",
    //   url: "/Industries/financial",
    //   className: "",
    // },
  ];

  const About_nav = [
    {
      name: "Company",
      url: "/About/company",
      className: "",
    },
    // {
    //   name: "Our Leadership",
    //   url: "",
    //   className: "",
    // },
    // {
    //   name: "Contact Us",
    //   url: "",
    //   className: "",
    // },
    // {
    //   name: "Newsroom",
    //   url: "",
    //   className: "",
    // },
    // {
    //   name: "Case Studies",
    //   url: "",
    //   className: "",
    // },
  ];

  const Solutions_nav = [
    {
      name: "Solutions",
      url: "/Solutions",
      className: "",
    },
  ];

  const Careers_nav = [
    {
      name: "Job postings",
      url: "",
      className: "",
    },
  ];

  const Hundlereq = async () => {
    // const apiKey = "ITwJn1-79Jlu9UNtBm7sZEnCNsgInMOUbUl9BJ1iRDo";
    const url =
      "https://date-time.netryde.com/api/USDateAndTime/us-date-time?timeZone=Pacific";

    fetch(url, {
      method: "GET",
      headers: {
        "api-key": `ITwJn1-79Jlu9UNtBm7sZEnCNsgInMOUbUl9BJ1iRDo`,
        Origin: "Access-Control-Allow-Origin",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => console.log("kitimedate", data))
      .catch((error) => console.error("ErrTimeDate:", error));
  };

  return (
    <div className="header-container">
      <div className="nav-container">
        <div className="logo">
          <img
            src={Logo}
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <div className="nav-links">
          <NavLink
            className={menuTitle === "" ? "nav-link-active" : "nav-link"}
            to={"/"}
            onMouseOver={() => {
              setMenuTitle("");
              setIsMenuOpen(false);
            }}
          >
            Home
          </NavLink>
          <NavLink
            className={
              menuTitle === "Services" ? "nav-link-active" : "nav-link"
            }
            to={"/Services/modernization"}
            onMouseOver={() => {
              setMenuTitle("Services");
              setIsMenuOpen(true);
              setMenuLinks({
                link_item: Services_nav,
              });
            }}
          >
            <span>Services</span>
          </NavLink>
          <NavLink
            className={
              menuTitle === "Industries" ? "nav-link-active" : "nav-link"
            }
            to={""}
            onMouseOver={() => {
              setMenuTitle("Industries");
              setIsMenuOpen(true);
              setMenuLinks({
                link_item: Industries_nav,
              });
            }}
          >
            Industries
          </NavLink>
          <NavLink
            className={
              menuTitle === "Solutions" ? "nav-link-active" : "nav-link"
            }
            to={""}
            onMouseOver={() => {
              setMenuTitle("Solutions");
              setIsMenuOpen(true);
              setMenuLinks({
                link_item: Solutions_nav,
              });
            }}
          >
            Solutions
          </NavLink>
          <NavLink
            className={menuTitle === "About" ? "nav-link-active" : "nav-link"}
            to={""}
            onMouseOver={() => {
              setMenuTitle("About");
              setIsMenuOpen(true);
              setMenuLinks({
                link_item: About_nav,
              });
            }}
          >
            About
          </NavLink>

          <NavLink
            className={menuTitle === "Careers" ? "nav-link-active" : "nav-link"}
            to={""}
            onMouseOver={() => {
              setMenuTitle("Careers");
              setIsMenuOpen(true);
              setMenuLinks({
                link_item: Careers_nav,
              });
            }}
          >
            Careers
          </NavLink>
          <NavLink
            className="contact-link"
            to={""}
            onClick={() => {
              Hundlereq();
            }}
          >
            <IoMdContacts /> Contact Us
          </NavLink>
        </div>

        {/* mobile nav links */}
        <div className="mobile-nav-links">
          {isMobileMenuOpen ? (
            <HiOutlineMenuAlt2
              className="mobile-menu-icon"
              fontSize={30}
              onClick={() => {
                setIsMobileMenuOpen(isMobileMenuOpen ? false : true);
              }}
            />
          ) : (
            <HiOutlineMenuAlt3
              className="mobile-menu-icon"
              fontSize={30}
              onClick={() => {
                setIsMobileMenuOpen(isMobileMenuOpen ? false : true);
              }}
            />
          )}
          {isMobileMenuOpen === true && (
            <div className="mobile-link-items">
              <NavLink
                className={menuTitle === "" ? "nav-link-active" : "nav-link"}
                to={"/"}
                onMouseOver={() => {
                  setMenuTitle("");
                  setIsMenuOpen(false);
                }}
              >
                Home
              </NavLink>
              <NavLink
                className={
                  menuTitle === "Services" ? "nav-link-active" : "nav-link"
                }
                to={"/Services/modernization"}
                onMouseOver={() => {
                  setMenuTitle("Services");
                  setIsMenuOpen(true);
                  setMenuLinks({
                    link_item: Services_nav,
                  });
                }}
              >
                <span> Services </span>
              </NavLink>
              <NavLink
                className={
                  menuTitle === "Industries" ? "nav-link-active" : "nav-link"
                }
                href={""}
                onMouseOver={() => {
                  setMenuTitle("Industries");
                  setIsMenuOpen(true);
                  setMenuLinks({
                    link_item: Industries_nav,
                  });
                }}
              >
                Industries
              </NavLink>
              <NavLink
                className={
                  menuTitle === "About" ? "nav-link-active" : "nav-link"
                }
                to={"/soulutions"}
                onMouseOver={() => {
                  // setMenuTitle("About");
                  // setIsMenuOpen(true);
                  setMenuLinks({
                    link_item: Solutions_nav,
                  });
                }}
              >
                Solutions
              </NavLink>
              <NavLink
                className={
                  menuTitle === "About" ? "nav-link-active" : "nav-link"
                }
                href={""}
                onMouseOver={() => {
                  setMenuTitle("About");
                  setIsMenuOpen(true);
                  setMenuLinks({
                    link_item: About_nav,
                  });
                }}
              >
                About
              </NavLink>
              <NavLink
                className={
                  menuTitle === "Careers" ? "nav-link-active" : "nav-link"
                }
                href={""}
                onMouseOver={() => {
                  setMenuTitle("Careers");
                  setIsMenuOpen(true);
                  setMenuLinks({
                    link_item: Careers_nav,
                  });
                }}
              >
                Careers
              </NavLink>
              <NavLink
                className="login-link"
                to={""}
                onMouseOver={() => {
                  setMenuTitle("login");
                  setIsMenuOpen(false);
                }}
              >
                <IoMdContacts className="login-icon" /> Contact Us
              </NavLink>
            </div>
          )}
        </div>
        {/* mobile nav links */}
      </div>

      {isMenuOpen === true && (
        <div className="menu-items-container">
          <div className="menu-items">
            <div className="menu-title">
              <h1> {menuTitle} </h1>
            </div>
            <div className="child-links">
              {/* <ul> */}
              {muneLinks.link_item?.map((menuLink) => {
                return (
                  <NavLink
                    className="nav-link"
                    to={menuLink.url}
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  >
                    {menuLink?.name}
                  </NavLink>
                );
              })}
              {/* </ul> */}
            </div>
          </div>
          <div className="cancel-btn">
            <button
              onClick={() => {
                setMenuTitle("");
                setIsMenuOpen(false);
              }}
            >
              <IoMdClose />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
