import React, { useEffect, useState } from "react";
import { IoArrowForwardSharp } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import HeadingImage from "../../resource/Heading_background.png";
import "../../components/styles.css";
import { VscDebugBreakpointData } from "react-icons/vsc";
import Card from "../../components/Card";

const Core_Services_cards = [
  {
    text: "Design",
    url: "/Services/modernization",
  },
  {
    text: "Implement",
    url: "",
  },
  {
    text: "Scale out",
    url: "",
  },
];

function Education() {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveRoute(location.pathname);
  }, []);

  return (
    <>
      <div
        className="heading-container"
        style={{
          // backgroundImage: `url(${ImagePattern})`,
          backgroundImage: `url("https://img.freepik.com/free-vector/abstract-circular-halftone-design-background_1055-17747.jpg?t=st=1718634316~exp=1718637916~hmac=c490d70b10b2f6ad2bd4714cfe61e3abe76407004f127810ea44865461e1324a&w=1800")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div className="heading--content">
          <h1>
            <strong> Education </strong>
          </h1>
          <span>
            See how we help build and scale, high-performance software
            development for organizations.
          </span>
          <NavLink className="call-to-action" to={"/solutions"}>
            <strong>
              Explore Our Solutions
              <IoArrowForwardSharp className="icon" id="icon" />
            </strong>
          </NavLink>
        </div>
      </div>

      <div
        className="heading-container"
        style={{
          // backgroundImage: `url(${HeadingImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="main-section-content">
          <h1>
            <strong>Our Capabilities</strong>
          </h1>
          <div className="our-capabilities-container">
            <span>
              {" "}
              <VscDebugBreakpointData /> Agile
            </span>
            <span>
              <VscDebugBreakpointData /> Product Management
            </span>
            <span>
              {" "}
              <VscDebugBreakpointData /> Software Development
            </span>
            <span>
              <VscDebugBreakpointData /> Architecture
            </span>
            <span>
              <VscDebugBreakpointData /> DevOps
            </span>
            <span>
              <VscDebugBreakpointData /> Testing
            </span>
            <span>
              <VscDebugBreakpointData /> Architecture
            </span>
          </div>
        </div>
      </div>

      <div
        className="heading-container"
        style={{
          backgroundImage: `url(${HeadingImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          color: "black",
        }}
      >
        <div className="main-section-content">
          <h1>
            <strong> We use Agile quality engineering Method </strong>
          </h1>
          <span>
            Agile enables you to deliver better products faster and more
            effectively than your competition
          </span>
          <NavLink className="call-to-action" to={activeRoute}>
            <strong>
              Learn more
              <IoArrowForwardSharp className="icon" id="icon" />
            </strong>
          </NavLink>
        </div>
      </div>

      <div
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div className="pattern">
          <div className="message-content">
            <div className="section">
              <h1>
                <strong> How </strong> We Work With You
              </h1>
              <div className="card-group">
                {Core_Services_cards.map((Core_Services_card, id) => (
                  <Card
                    key={id}
                    className="card-inside"
                    value={Core_Services_card.text}
                    onClick={() => {
                      //   navigate(Core_Services_card.url);
                    }}
                  />
                ))}
              </div>

              <div className="card-group">
                <NavLink className="call-to-action" to={activeRoute}>
                  <strong>
                    Learn more about our opperations
                    <IoArrowForwardSharp className="icon" id="icon" />
                  </strong>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Education;
