import React, { useEffect, useState } from "react";
import { IoArrowForwardSharp } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import HeadingImage from "../../resource/Heading_background.png";
import "../../components/styles.css";
import { VscDebugBreakpointData } from "react-icons/vsc";
import Card from "../../components/Card";
import NavGroup from "../../components/NavGroup";

const linkList = [
  {
    text: "Enterprise Resource Planning (ERP)",
    url: "/Services/modernization",
    detail: [
      "Integrated Finance Management System (IFMS)",
      "Integrated Knowledge Management System (IKMS)",
      "Purchasing and Supply Management System (PSMS)",
      "Integrated Construction Management System (ICMS)",
      "Integrated Project Management System (IPMS)",
      "Integrated Civil Service Information System (ICSIS)",
    ],
  },
  {
    text: "National Integrated Justice Information System (NIJIS)",
    url: "",
    detail: [
      "E-Justice Blueprint",
      "E-Justice Strategy",
      "Public Service Delivery",
      "Separation of Powers",
    ],
  },
  {
    text: "Integrated Socio-economic Development Information System(ISDIS)",
    url: "",
    detail: [
      "e-Education",
      "e-Environment",
      "e-Health",
      "e-Agriculture",
      "e-Demography",
    ],
  },
  {
    text: "Integrated Higher Education Information System(IHDIS)",
    url: "",
    detail: [
      "Registrar System",
      "Schedule",
      "Placement",
      "E-Library",
      "E-learning",
    ],
  },
];

function Solutions() {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState();
  const [activeDetail, setActiveDetail] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveRoute(location.pathname);
  }, []);

  console.log("activeDetail.detail", activeDetail.detail);

  return (
    <>
      <div
        className="heading-container"
        style={{
          // backgroundImage: `url(${ImagePattern})`,
          backgroundImage: `url("https://img.freepik.com/free-vector/abstract-circular-halftone-design-background_1055-17747.jpg?t=st=1718634316~exp=1718637916~hmac=c490d70b10b2f6ad2bd4714cfe61e3abe76407004f127810ea44865461e1324a&w=1800")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div className="heading--content">
          <h1>
            <strong> Our Solutions </strong>
          </h1>
          <span> Explore our high-performance software solutions </span>
          {/* <NavLink className="call-to-action" to={""}> */}
          <strong style={{ color: "#4eb0e2" }}>
            <a
              className="call-to-action"
              href="mailto:thabtu@cybersoft-intl.com"
            >
              {" "}
              Request a Demo
            </a>

            <IoArrowForwardSharp className="icon" id="icon" />
          </strong>
          {/* </NavLink> */}
        </div>
      </div>

      <div
        style={{
          // backgroundImage: `url(${ImagePattern})`,
          //   backgroundImage: `url(${ImagePattern})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="pattern">
          <div className="message-content flex">
            <div className="section">
              <h1>
                <strong> We Develop</strong> Advanced Software Solutions.
              </h1>
              <span>
                By leveraging our expertise and latest technologies we find solutions to your most challenging
                business problems.
              </span>
              {/* <NavLink  to={"/"}> */}
              <strong style={{ color: "#4eb0e2" }}>
                <a className="call-to-action" href="tel:+251911221415">
                  Schedule a Discovery Call
                </a>
                <IoArrowForwardSharp className="icon" id="icon" />
              </strong>
              {/* </NavLink> */}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          // backgroundImage: `url(${ImagePattern})`,
          //   backgroundImage: `url("https://img.freepik.com/free-vector/abstract-circular-halftone-design-background_1055-17747.jpg?t=st=1718634316~exp=1718637916~hmac=c490d70b10b2f6ad2bd4714cfe61e3abe76407004f127810ea44865461e1324a&w=1800")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div
          className="heading-container"
          style={{
            //   backgroundImage: `url(${CoreServicesImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
          }}
        >
          <div className="main-section-content">
            <h1>
              <strong> Core Focus Areas </strong>
            </h1>
            <div className="list-switch-container">
              <NavGroup
                links={linkList}
                onClick={(e) => {
                  setActiveDetail(e);
                }}
                activeLink={activeDetail.name}
              />
              <div className="switch-detail">
                <h2> {activeDetail.text} </h2>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // minWidth: "fit-content",
                  }}
                >
                  {activeDetail.detail != undefined &&
                    activeDetail.detail.map((detail) => (
                      <p
                        className="box-shadow"
                        style={{
                          marginTop: "15px",
                          color: "#000",
                          width: "fit-content",
                        }}
                      >
                        {" "}
                        {detail}
                      </p>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Solutions;
